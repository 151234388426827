import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import linkedin from '../img/social/linkedin.svg'
import github from '../img/social/github.svg'
import stackoverflow from '../img/social/stackoverflow.svg'
// import twitter from '../img/social/twitter.svg'
import medium from '../img/social/medium.svg'
import logo from '../img/logo.svg'
import { HTMLContent } from '../components/Content'

export const IndexPageTemplate = ({
  title,
  heading,
  subheading,
  image,
  content
}) => (
  <div
    className="home-div margin-top-0"
    // style={{
    //   backgroundImage: `url(${
    //     !!image.childImageSharp ? image.childImageSharp.fluid.src : image
    //   })`
    // }}
  >
    
    <div className="columns container">
      <div className="column is-half">
        <div className="home-title">
          <img
            className="profile-pic"
            src={image}
            alt="Tahmeed Tarek"
          />
          <h1 className="header">
            {heading}
          </h1>
          <h4 className="subheader">
            {subheading}
          </h4>
        </div>
      </div>

      <div className="column is-half">
        <div className="home-story">
          <section>
            <p className="home-story-header">
              ~ Bonjour ~
            </p>
            <p className="home-story-text">
              <HTMLContent content={content} />
            </p>
            <img
              src={logo}
              alt="logo"
            />
          </section>
        </div>
      </div>
    </div>

    <div className="home-empty-div">
      {/* Some negative space */}
    </div>

    <div className="home-nav">
      <div className="home-nav-pages">
        <Link className="nav-item link" to="/projects">
          projects 
        </Link>
        {/* <Link className="nav-item link" to="/blog">
          blog 
        </Link> */}
        <Link className="nav-item link" to="/story">
          story
        </Link>
        <Link className="nav-item link" to="/contact">
          contact 
        </Link>
      </div>
      <div className="home-links">
        <a className="home-link-item" title="github" target="_blank" rel="noreferrer" href="https://github.com/tahmeed156">
          <img
            src={github}
            alt="Github"
          />
        </a>
        {/* <a className="home-link-item" title="twitter" target="_blank" rel="noreferrer" href="https://twitter.com/tahmeed156">
          <img
            src={twitter}
            alt="Twitter"
          />
        </a> */}
        <a className="home-link-item" title="medium" target="_blank" rel="noreferrer" href="https://medium.com/@tahmeed156">
          <img
            src={medium}
            alt="Medium"
          />
        </a>
        <a className="home-link-item" title="stack-overflow" target="_blank" rel="noreferrer" href="https://stackoverflow.com/users/6267007/tahmeed156">
          <img
            src={stackoverflow}
            alt="Stack Overflow"
          />
        </a>
        <a className="home-link-item" title="linkedin" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/tahmeed156">
          <img
            src={linkedin}
            alt="Linkedin"
          />
        </a>
      </div>
    </div>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { html } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        image={frontmatter.image}
        content={html}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      id
      html
      frontmatter {
        title
        heading
        subheading
        image
      }
    }
  }
`
